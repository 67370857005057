import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
  enabled: true,

  dsn: 'https://0143220afc1635f79a0a280e742b8db0@o1204727.ingest.us.sentry.io/4506875935916032',
  tracesSampleRate: 0.25,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.05,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],

  ignoreErrors: ['Non-Error promise rejection captured'],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
