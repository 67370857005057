import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [6],
		"/auth/forgot": [7,[2]],
		"/auth/logout": [8,[2]],
		"/auth/recovery": [9,[2]],
		"/auth/sign-in": [10,[2]],
		"/auth/sign-up": [11,[2]],
		"/cases": [12],
		"/cases/[caseId]": [13],
		"/meeting": [14,[3]],
		"/meeting/active": [18,[3]],
		"/meeting/archive": [19,[3]],
		"/meeting/finish": [20,[3]],
		"/meeting/[meetingId]": [15,[3,4]],
		"/meeting/[meetingId]/lobby": [17,[3,4]],
		"/meeting/[meetingId]/[breakoutId]": [16,[3,4]],
		"/profile": [21],
		"/settings": [22],
		"/stats": [23,[5]],
		"/stats/aggregate-data": [24,[5]],
		"/stats/team-members": [25,[5]],
		"/stats/uber-interactions": [26,[5]],
		"/statuses": [27],
		"/tickets": [28],
		"/tickets/[ticketId]": [29],
		"/types": [30],
		"/users": [31],
		"/users/new-user": [32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';